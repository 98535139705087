// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


document.addEventListener("turbolinks:load", function() {
	var geocoder, location1, location2;
	var count;
	var points = new Array();
	var location = new google.maps.LatLng(-36.912177, 174.881209);
	var myOptions = {
		zoom: 13,
	    center: location,
	    navigationControl: true,
	    navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
	    mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR},
	    scaleControl: false,
	    scrollwheel: false,
	    mapTypeId: google.maps.MapTypeId.ROADMAP
	}
	map = new google.maps.Map(document.getElementById("map"), myOptions);


	var location_pin = new google.maps.Marker({ position: location, map: map});
});
